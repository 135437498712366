.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.main-container {
  padding-right: 0px;
  padding-left: 0px;
}

@media (min-width: 576px) {
  .main-container {
    max-width: 100%;
  }
}